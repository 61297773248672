:root {
	--text-font: Inter, sans-serif;
	--primary-bg-color: #ffffff;
	--secondary-bg-color: #f6f6f4;
	--text-color: #48413f;
	--border-color: #e5e4e1;
	--accent-color: #10a37f;
	--accent-hover-color: #0d8c6d;
	--card-bg-color: #ffffff;
	--input-bg-color: #f6f6f4;
	--shadow-color: rgba(56, 49, 47, 0.03);
	--dropdown-hover-bg: #f0f0f0;
	--dropdown-hover-text: #333333;
	box-sizing: border-box;
}

/* Dark theme styles */
[data-theme="dark"] {
	--primary-bg-color: #333333; /* Main page background - lighter than before */
	--secondary-bg-color: #2a2a2a; /* Sidebar background */
	--text-color: #e0e0e0;
	--border-color: #333333;
	--card-bg-color: #4d4d4d; /* Chat container background - adjusted for better contrast */
	--input-bg-color: #2a2a2a;
	--shadow-color: rgba(0, 0, 0, 0.2);
	--user-message-bg: #222222; /* Darker user message background for better contrast */
	--user-message-text: #ffffff; /* Brighter white text for user messages */
	--dropdown-hover-bg: #444444; /* Lighter background for dropdown hover in dark mode */
	--dropdown-hover-text: #ffffff; /* White text for dropdown hover in dark mode */
}

/* Custom dark mode styles that override Fabric UI */
.bamboo-dark-theme {
	background-color: var(--primary-bg-color);
	color: var(--text-color);
}

.bamboo-dark-theme #root,
.bamboo-dark-theme .App,
.bamboo-dark-theme .App > div {
	background-color: var(--primary-bg-color);
	color: var(--text-color);
}

/* Target the StyledBoxV2 component with a simpler selector */
.bamboo-dark-theme div[class*="StyledBoxV2"],
.bamboo-dark-theme div[class*="Box"],
.bamboo-dark-theme div[class*="neutral-extra-extra-weak"] {
	background-color: var(--primary-bg-color);
}

/* Direct style for the StyledBoxV2 component */
.bamboo-dark-theme .App > div[class*="StyledBoxV2"] {
	background-color: var(--primary-bg-color);
}

/* Override the neutral-extra-extra-weak background */
.bamboo-dark-theme div[class*="neutral-extra-extra-weak"] {
	background-color: var(--primary-bg-color);
}

/* Target the specific Fabric UI class for the neutral background */
.bamboo-dark-theme .neutral-extra-extra-weak-bg {
	background-color: var(--primary-bg-color);
}

/* Target the specific Fabric UI class for StyledBoxV2 */
.bamboo-dark-theme div[class*="StyledBoxV2-sc"] {
	background-color: var(--primary-bg-color);
}

/* Additional style to ensure StyledBoxV2 respects our theme */
.bamboo-dark-theme .StyledBoxV2-sc {
	background-color: var(--primary-bg-color);
}

/* Target the sidebar specifically */
.bamboo-dark-theme .sideMenu {
	background-color: var(--primary-bg-color);
	color: var(--text-color);
}

/* Target the chat container with a simpler selector */
.bamboo-dark-theme .chatContainer {
	background-color: var(--card-bg-color);
	color: var(--text-color);
	box-shadow: 0 0 10px var(--shadow-color);
}

/* Target any StyledBoxV2 components inside the chat container */
.bamboo-dark-theme .chatContainer div[class*="StyledBoxV2"],
.bamboo-dark-theme .chatContainer div[class*="Box"] {
	background-color: transparent;
}

/* Improve contrast for user chat messages in dark mode */
.bamboo-dark-theme .chatLog .react-markdown {
	color: var(--user-message-text);
	font-weight: 500;
}

/* Add a direct rule for the chat prompt text */
.bamboo-dark-theme .chatLog .react-markdown p {
	color: var(--user-message-text);
	font-weight: 500;
}

/* Target all paragraphs in user messages for better visibility */
.bamboo-dark-theme .chatLog div[style*="borderRadius: 16px 16px 0px 16px"] p {
	color: var(--user-message-text);
	font-weight: 500;
}



.App {
	font-family: var(--text-font);
	text-align: center;
	display: flex;
	height: 100vh;
	color: var(--text-color);
	background-color: var(--primary-bg-color);
}

header {
	display: none;
}

header h1 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--text-color);
	margin: auto;
}

.menu button {
	background: none;
	border: none;
}

nav {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	height: 100vh;
}

.navItems {
	padding: 8px;
	width: 100%;
	max-width: 340px;
}

.navPromptWrapper {
	overflow: auto;
	width: 288px;
}

.navPromptWrapper .categoryContainer:not(:first-child) {
	margin-top: 20px;
}

.navPrompt {
	display: flex;
	align-items: center;
	padding: 10px 12px;
	text-align: left;
	position: relative;
}

.navPrompt div {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}

.navPrompt input {
	background: none;
}

.navPrompt button {
	background: none;
	border: none;
}

.navPrompt button:hover {
	background: none;
	border: none;
}

.visible {
	visibility: visible;
}

.hidden {
	visibility: hidden;
}

.navPrompt-item {
	display: flex;
	align-items: center;
	column-gap: 8px;
	text-decoration: none;
	color: var(--text-color);
}

.navPrompt-item.active,
.navPrompt-item:hover {
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
}

.navPrompt p {
	font-size: 14px;
	line-height: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 200px;
	margin: 0;
	text-align: justify;
}

.navPrompt span {
	animation: fadeInChar 1s ease-in forwards;
	opacity: 0;
}

#navPrompt-input {
	color: var(--text-color);
	background: var(--input-bg-color);
	width: 100%;
}

#botMessage pre {
	margin: 0;
	padding: 0;
	text-indent: 0;
	white-space: pre-wrap;
	top: -45px;
	font-size: 16px;
}

pre {
	font-family: var(--text-font);
}

.react-markdown {
	margin: 0;
	max-width: 100%;
}

.react-markdown p {
	margin: 0;
	text-align: left;
}

@keyframes fadeInChar {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.navCloseIcon {
	width: 20%;
	margin: 10px;
}

nav svg {
	float: left;
}

.sideMenu {
	top: 0;
	left: 0;
	height: 100%;
	padding: 0 8px;
	display: flex;
	flex-direction: column;
	transition: width 0.35s ease-in-out;
	position: relative;
	margin-right: 24px;
	background-color: var(--secondary-bg-color);
	color: var(--text-color);
}

.sideMenu-title {
	font-size: 26px;
	font-weight: 600;
	line-height: 34px;
	color: var(--text-color);
	margin: 0;
	padding: 0;
	overflow-wrap: anywhere;
	word-break: normal;
}

.sideMenu-collapse-button {
	width: 32px;
	height: 28px;
	border-radius: 10px;
	background-color: transparent;
	border: 1px solid var(--border-color);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 8px;
	color: var(--text-color);
}

.sideMenu-collapse-button:hover {
	background-color: transparent;
	border: 1px solid #c6c2bf;
	border-radius: 20%;
}

.navPrompt.active,
.navPrompt:hover {
	cursor: pointer;
	background-color: #2e7918;
	color: #ffffff;
	border-radius: 10px;
}

.chatBox {
	flex: 1;
	line-height: 24px;
	color: var(--text-color);
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#introsection::before,
#introsection::after {
	float: left;
	color: rgb(0, 134, 244);
}

#introsection {
	text-align: left;
	padding: 20px;
}

#introsection h1 {
	padding-top: 20px;
	line-height: 33px;
}

#introsection h2 {
	font-size: 16px;
	font-weight: 400;
}

#introsection ul {
	list-style-type: square;
}

#introsection pre {
	margin: 0;
	display: inline;
}

#introsection a {
	color: var(--accent-color);
}

#statusMessage a {
	color: var(--accent-color);
}

#hyperlinks a {
	color: rgb(255, 255, 255);
}

.chatLog {
	margin-right: 45px;
}

.chatLogRowWrapper {
	overflow-y: auto;
	max-width: 800px;
	width: 80%;
	padding: 0 20px;
	box-sizing: content-box;
	height: -webkit-fill-available;
}

.userSVG {
	transform: scale(0.6);
}

.botResponseContainer {
	position: relative;
}

.stop-message {
	position: fixed;
	bottom: 90px;
	right: 35px;
	width: fit-content;
	padding: 10px 15px;
	border-radius: 10%;
	border: 1px solid var(--border-color);
	background-color: var(--secondary-bg-color);
	color: var(--text-color);
	cursor: pointer;
}

.errorMessage {
	color: #ef4444;
	font-size: 16px;
	line-height: 24px;
}

#avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	object-fit: contain;
	object-position: center;
}

.intro-form {
	width: 100%;
	min-width: 400px;
	border: 1px solid var(--border-color);
	border-radius: 8px;
	background-color: var(--input-bg-color);
}

.intro-form-textarea {
	outline: none;
	border: none;
	overflow-y: auto;
	resize: none;
	line-height: 28px;
	color: var(--text-color);
	background-color: var(--input-bg-color);
	padding: 0;
	max-height: 200px;
}

.inputForm {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

.inputPromptContainer {
	max-height: 324px;
	padding-bottom: 24px;
	margin: 0px;
	position: relative;
}

.inputPromptWrapper {
	padding: 5px;
	position: relative;
	width: 80%;
	max-height: 160px;
	background-color: var(--input-bg-color);
	border-radius: 5px;
	box-shadow: 0 0 2px 0 var(--shadow-color);
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inputPromptTextarea {
	flex: 1 1 auto;
	resize: none;
	background-color: var(--input-bg-color);
	color: var(--text-color);
	font-size: 16px;
	outline: none;
	border: none;
	overflow-y: auto;
	padding-right: 35px;
	max-height: 140px;
}

form button {
	background: var(--secondary-bg-color);
	border: none;
	flex-shrink: 0; /* don't let the button shrink */
	flex-grow: 0; /* don't let the button grow */
	margin-left: 10px; /* space between button and textarea */
	width: 35px;
	height: 30px;
}

.submit-prompt-btn {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.loginContainer {
	background: var(--primary-bg-color);
	width: 100%;
	height: 100%;
	font-family: var(--text-font);
}

.loginContainerContent {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

.loginContainerContent svg {
	padding-bottom: 30px;
}

.loginContainer h1 {
	font-size: 30px;
	margin: 0;
}

.loginContainer p {
	font-size: 18px;
	line-height: 5px;
}

.loginButtonWrapper {
	display: flex;
	align-items: center;
	column-gap: 15px;
	margin-top: 20px;
}

#loginButton {
	padding: 10px 25px;
	border-radius: 7px;
	color: #fff;
	background: var(--accent-color);
	border: none;
	font-size: 16px;
	cursor: pointer;
}

#loginButton:hover {
	background-color: var(--accent-hover-color);
}

#introsection {
	font-size: 14px;
}

#introsection h1,
pre {
	font-size: 16px;
	white-space: pre-wrap;
}

#introsection h2 {
	font-size: 14px;
}

.introContainer {
	display: flex;
	justify-content: center;
}

.chatSessionNameContainer {
	width: 80%;
	text-align: left;
}

.chatSessionNameContainer h3 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.chatContainer {
	flex: 1;
	background-color: var(--card-bg-color) !important;
	border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 0px 2px;
	display: flex;
	flex-direction: column;
	height: 92%;
	gap: 20px;
	color: var(--text-color);
}

/* Add the following classNames to style the UserAvatar component */

.user-avatar-container {
	position: relative;
	display: inline-block;
}

.avatar-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.avatar-image {
	width: 40px;
	height: 40px;
	border-radius: 8px;
	object-fit: cover;
}

.dropdown-container {
	position: absolute;
	top: 100%;
	right: 0;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
	z-index: 1;
	overflow: hidden;
	max-width: 368px;
	min-width: 192px;
	background-color: var(--card-bg-color);
	border-radius: 8px;
	box-sizing: border-box;
	border: 1px solid var(--border-color);
	padding: 4px 0;
}

.dropdown-menu {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	max-width: 368px;
	overflow: hidden;
}

.dropdown-item {
	padding: 10px 16px;
	background-color: var(--card-bg-color);
	border: none;
	text-align: left;
	width: 100%;
	align-items: center;
	color: var(--text-color);
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	font-size: 15px;
	line-height: 22px;
	min-height: 42px;
	text-decoration: none;
	-webkit-user-select: none;
	user-select: none;
}

.dropdown-item:hover {
	background-color: var(--dropdown-hover-bg);
	color: var(--dropdown-hover-text);
}

@media screen and (max-width: 1024px) {
	.App {
		flex-direction: column;
	}

	header {
		display: flex;
		align-items: center;
		background: var(--secondary-bg-color);
		border-bottom: 1px solid var(--border-color);
		padding: 4px 0 0 12px;
	}

	nav {
		display: flex;
	}

	.chatBox {
		position: static;
	}

	.chatPromptWrapper {
		padding: 12px;
	}

	.stop-message {
		right: 5px;
		font-size: 13px;
		padding: 8px 15px;
	}

	.userSVG {
		transform: scale(0.5);
	}

	#avatar {
		width: 30px;
		height: 30px;
	}
}

@media screen and (min-width: 1536px) {
	.loginContainer h1 {
		font-size: 40px;
	}

	.loginContainer p {
		font-size: 25px;
	}

	#loginButton {
		font-size: 22px;
	}
}
